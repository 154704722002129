<template>
  <div :class="$style['tabs']">
    <div
      v-for="item in tabs"
      :key="item.id"
      :title="item.title"
      :class="[
        $style['tabs-item'],
        value === item.id && $style['is-active'],
      ]"
      @click="changeTab(item.id)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EzfChartDataGroups',
  props: {
    value: {
      type: String,
      default: 'week',
    },
  },

  data() {
    return {
      tabs: [{
        id: 'year',
        name: `1${this.$t('date.year')[0]}`,
        title: `1 ${this.$t('date.year')}`,
      }, {
        id: 'quarter',
        name: `1${this.$t('date.quarter')[0]}`,
        title: `1 ${this.$t('date.quarter')}`,
      }, {
        id: 'month',
        name: `1${this.$t('date.month')[0]}`,
        title: `1 ${this.$t('date.month')}`,
      }, {
        id: 'week',
        name: `1${this.$t('date.week')[0]}`,
        title: `1 ${this.$t('date.week')}`,
      // }, {
        //   id: 'day',
      //   name: `1${this.$t('date.day')[0]}`,
      //   title: `1 ${this.$t('date.day')}`,
      }],
    };
  },

  methods: {
    changeTab(id) {
      this.$emit('input', id);
    },
  },
};
</script>

<style lang="scss" module>
.tabs {
  display: flex;
  justify-content: flex-end;
}

.tabs-item {
  border: 1px solid #C2C8CF;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 4px;
  padding: 5px;
  min-width: 32px;
  text-align: center;
  color: #C2C8CF;
  cursor: pointer;
}

.is-active{
  color: #FFFFFF;
  background-color: #C2C8CF;
}
</style>
