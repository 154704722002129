<script>
import ezTableColumn from '@dmant/ez-ui-components/src/components/table/table-column';
import ezfTableColumn from './column';

export default {
  name: 'EzfTableGroup',
  components: { ezTableColumn },
  props: {
    group: {
      type: Object,
      default: () => ({
        label: '',
        columns: [],
      }),
    },
    total: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.ezfTableColumn = ezfTableColumn;
  },
};
</script>

<template>
  <ez-table-column>
    <template slot="header">
      <div class="text-center">{{ group.label }}</div>
    </template>

    <component
      v-for="(column, index) in group.columns"
      :key="index"
      :is="ezfTableColumn"
      v-bind="column"
      :total="total"
    />
  </ez-table-column>
</template>
