import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';
import orderBy from 'lodash/orderBy';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isGeolocatonLoading: false,
      meta: null,
    };
  },

  computed: {
    ...mapState(['currency']),

    getTableData() {
      const sumFields = ['uniqueVisitors', 'leads', 'revenue', 'customers'];
      const avgFields = ['leadGain', 'visitorValue', 'customerValue'];

      const total = sumFields.concat(avgFields).reduce((obj, key) => {
        obj[key] = 0;
        return obj;
      }, {});
      total.id = this.$t('geolocation_table.total');

      // this.getGeolocation is component method (store getter)
      if (this.getGeolocation.length) {
        let newGeolocation = null;
        const groupedIds = ['', 'Unknown'];
        const unknowns = this.getGeolocation.filter((obj) => groupedIds.includes(obj.id));

        // fix id="Unknown" or id=""
        if (unknowns.length) {
          const newGroupedObj = sumFields.concat(avgFields).reduce((obj, key) => {
            obj[key] = sumBy(unknowns, key);
            return obj;
          }, {});
          newGroupedObj.id = 'unknown';

          newGeolocation = this.getGeolocation.reduce((arr, obj) => {
            if (!groupedIds.includes(obj.id)) arr.push(obj);
            return arr;
          }, []);

          newGeolocation.push(newGroupedObj);
        }

        const geolocationData = newGeolocation || this.getGeolocation;

        sumFields.map((field) => {
          total[field] = sumBy(geolocationData, field);
        });

        // avgFields.map((field) => {
        //   total[field] = meanBy(geolocationData, field);
        // });

        // fix calc total statistics
        total.leadGain = meanBy(geolocationData, 'leadGain');
        total.visitorValue = (total.uniqueVisitors)
          ? total.revenue / total.uniqueVisitors
          : 0;
        total.customerValue = (total.customers)
          ? total.revenue / total.customers
          : 0;

        const sorted = orderBy(
          geolocationData,
          ['revenue', 'uniqueVisitors'],
          ['desc', 'desc'],
        );
        return [total].concat(sorted);
      }
      return [total];
    },
  },

  methods: {
    beforeLoadGeolocationData() {
      this.isGeolocatonLoading = true;
      this.getMetaData();
    },

    afterLoadGeolocationData() {
      this.isGeolocatonLoading = false;
    },

    getColumnLabel(field) {
      return this.$t(`geolocation_table.columns_label.${field}`);
    },

    getTotalHint(field) {
      return this.$t(`geolocation_table.total_hint.${field}`);
    },

    formatValue(value) {
      return value.toString().replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
    },

    formatNumber(value) {
      return this.formatValue(Math.round(value));
    },

    formatNumberFix2(value) {
      return this.formatValue(value.toFixed(2));
    },

    formatCost(value) {
      const formated = this.formatValue(value.toFixed(2));
      return `${formated}\u00A0${this.currency.symbol}`;
    },

    getMetaData() {
      this.meta = {
        main: {
          name: (code) => this.$t(`geolocation_table.country_name.${code}`), // countryName
          field: 'id', // countryCode
          label: this.getColumnLabel('country'),
        },
        columns: [
          {
            field: 'uniqueVisitors',
            label: this.getColumnLabel('uniqueVisitors'),
            format: this.formatNumber,
          },
          {
            field: 'leadGain',
            label: this.getColumnLabel('leadGain'),
            hint: this.getTotalHint('leadGain'),
            format: this.formatNumberFix2,
          },
          {
            field: 'leads',
            label: this.getColumnLabel('leads'),
            format: this.formatNumber,
          },
          {
            field: 'revenue',
            label: this.getColumnLabel('revenue'),
            format: this.formatCost,
            percent: true,
          },
          {
            field: 'visitorValue',
            label: this.getColumnLabel('visitorValue'),
            hint: this.getTotalHint('visitorValue'),
            format: this.formatCost,
          },
          {
            field: 'customerValue',
            label: this.getColumnLabel('customerValue'),
            hint: this.getTotalHint('customerValue'),
            format: this.formatCost,
          },
        ],
      };
    },
  },
};
