<script>
import ezTableColumn from '@dmant/ez-ui-components/src/components/table/table-column';

export default {
  name: 'EzfTableColumn',
  components: { ezTableColumn },
  props: {
    field: {
      type: String,
      required: true,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    percent: {
      type: Boolean,
      default: false,
    },
    format: {
      type: Function,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    total: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    getFormatedValue(value) {
      return (this.format) ? this.format(value) : value;
    },
    getPercents(value, total) {
      return (total)
        ? ((value * 100) / total).toFixed(2)
        : (0).toFixed(2);
    },
  },
};
</script>

<template>
  <ez-table-column :prop="field">
    <div class="th" slot="header">
      <!-- <ez-icon
        class="th__icon"
        name="question-circle"
        title="lorem ipsum"
      ></ez-icon> -->
      <span>{{ label }}</span>
    </div>
    <template slot-scope="scope">
      <span v-if="scope.$index > 0">
        <span>{{ getFormatedValue(scope.row[field]) }}</span>
        <span v-if="percent && total[field]" class="geo-table__percent">
          ({{ getPercents(scope.row[field], total[field]) }}%)
        </span>
      </span>
      <div class="geo-table__total" v-else>
        <span class="geo-table__total-value">{{
          getFormatedValue(scope.row[field])
        }}</span>
        <span v-if="hint" class="geo-table__total-hint">{{ hint }}</span>
      </div>
    </template>
  </ez-table-column>
</template>
