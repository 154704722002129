<script>
import ezFlag from '@dmant/ez-ui-components/src/components/flag';
import ezTable from '@dmant/ez-ui-components/src/components/table';
import ezTableColumn from '@dmant/ez-ui-components/src/components/table/table-column';
import ezfTableGroup from './group';
import ezfTableColumn from './column';

export default {
  name: 'EzfTable',
  components: {
    ezFlag,
    ezTable,
    ezTableColumn,
    // ezfTableGroup,
    // ezfTableColumn,
  },
  props: {
    meta: {
      type: Object,
      default: () => null,
    },
    data: {
      type: Array,
      default: () => null,
    },
  },

  computed: {
    main() {
      return (this.meta) ? this.meta.main : null;
    },
    columns() {
      return (this.meta) ? this.meta.columns : null;
    },
  },

  methods: {
    getPercents(value, total) {
      return ((value * 100) / total).toFixed(2);
    },
    getTypeColumn(column) {
      return (column.group) ? ezfTableGroup : ezfTableColumn;
    },
  },
};
</script>

<template>
  <ez-table v-if="meta && data" :data="data" stripe class="geo-table">
    <ez-table-column
      v-if="main"
      :prop="main.field"
      :label="main.label"
      :width="200"
    >
      <template slot-scope="scope">
        <div class="geo-table__country" v-if="scope.$index > 0">
          <span class="geo-table__country-num">{{ scope.$index }}.</span>
          <template v-if="main.field && scope.row[main.field]">
            <ez-flag
              class="geo-table__country-flag"
              :country="scope.row[main.field]"
            ></ez-flag>
            <span>{{ main.name(scope.row[main.field]) }}</span>
          </template>
        </div>
        <div class="geo-table__total" v-else>
          <span class="geo-table__total-value">{{
            scope.row[main.field]
          }}</span>
          <span v-if="main.hint" class="geo-table__total-hint">{{
            main.hint
          }}</span>
        </div>
      </template>
    </ez-table-column>

    <template v-if="columns">
      <component
        v-for="(column, index) in columns"
        :key="index"
        :is="getTypeColumn(column)"
        v-bind="column"
        :total="data[0]"
      />
    </template>
  </ez-table>
</template>
