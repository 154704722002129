<script>
import merge from 'lodash/merge';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/dataZoom';

import ezPreloader from '@dmant/ez-ui-components/src/components/preloader';
import EchartsVue from '@dmant/ez-lib/src/echarts/echartsVue.vue';
import EzfEmpty from '@/components/empty/empty.vue';

const defaultColors = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'];

export default {
  name: 'EzfChart',
  components: {
    EchartsVue,
    ezPreloader,
    EzfEmpty,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    xfield: {
      type: String,
      default: 'date',
    },
    yfield: {
      type: String,
      default: 'value',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    // loading(value) {
    //   if (value) {
    //     this.$refs.chart.showLoading({
    //       text: 'Loading ...',
    //       // effect: '',
    //       // color: 'green',
    //       // textStyle: {
    //       //   fontSize: 20,
    //       // },
    //     });
    //   } else this.$refs.chart.hideLoading();
    // },
  },

  computed: {
    chartData() {
      return {
        names: this.data.map((item) => item[this.xfield]),
        values: this.data.map((item) => item[this.yfield]),
      };
    },

    isShowZoom() {
      return this.chartData.names.length > 40;
    },

    defaultOptions() {
      return {
        title: {
          show: this.title.length,
          text: this.title,
          x: '10px',
          y: '10px',
        },
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross',
          },
          padding: [5, 10],
          backgroundColor: 'rgba(255, 255, 255, 0.9);',
          textStyle: {
            color: '#5D6B7E',
          },
          extraCssText: 'box-shadow: 0px 2px 8px rgba(93, 107, 126, 0.24);text-align: center;',
        },
        grid: {
          left: 25,
          top: (this.title.length) ? 60 : 25,
          right: 25,
          bottom: this.isShowZoom ? 60 : 25,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.chartData.names,
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisPointer: {
            show: true,
            snap: true,
          },
        },
        dataZoom: [
          {
            type: 'slider',
            show: this.isShowZoom,
            // showDetail: false,
            left: '8%',
            right: '8%',
          },
          {
            type: 'inside',
            disabled: !this.isShowZoom,
          },
        ],
        series: [{
          data: this.chartData.values,
          type: 'line',
          smooth: false,
          lineStyle: {
            width: 3,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: this.options.color || defaultColors[0],
          },
          emphasis: {
            itemStyle: {
              borderWidth: 2,
              borderColor: this.options.color || defaultColors[0],
              color: this.options.color || defaultColors[0],
            },
          },
          symbolSize: 10,
        }],
        color: defaultColors,
        animation: false,
      };
    },

    getOptions() {
      return merge(this.defaultOptions, this.options);
    },

    isShowChart() {
      return this.loading || this.data.length;
    },
  },
};
</script>

<template>
  <div class="chart">
    <slot name="header" />
    <echarts-vue v-if="isShowChart" ref="chart" :options="getOptions" autoresize/>
    <ezf-empty v-else type="chart" img-width="70" class="empty">
      <template slot="title">
        {{ $t("charts.empty.title") }}
      </template>
      <template slot="description">
        {{ $t("charts.empty.description") }}
      </template>
    </ezf-empty>
    <slot name="footer" />
    <ez-preloader v-if="loading" value :stop="false" class="preloader" />
  </div>
</template>

<style lang="scss" scoped>
@import "@dmant/ez-ui-components/src/components/core/src/_variables.scss";

.chart {
  position: relative;
  border-radius: $border-radius * 2;
  // box-shadow: 0px 5px 15px rgba(128, 149, 170, 0.25);
  // padding: $offset * 2;
  background-color: $color-white;
  min-height: 400px;

  .echarts {
    width: 100%;
  }

  .preloader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    // background: rgb(0, 0, 0, 0.3);
    width: auto;
    height: auto;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }
}
</style>
